export interface jsonObj {
  [key: string]: any
};


export const config = {
  app_url: process.env.REACT_APP_LIVE_URL,
  apiUserUrl: "apiUserUrl/v1",
  apiurl_dev: process.env.REACT_APP_API_URL_DEV,
  apiurl: "https://console.xcellentlife.com/api/v1",
    // apiurl: "https://nlpbackend.xcellentlife.com/api/v1",
  socketurl: process.env.REACT_APP_SOCKET_URL,
  chatSocket: `${process.env.REACT_APP_CHAT_SOCKET}`
  // socketurl: "http://192.241.128.213:4000/",
  // apiurl_dev: "http://192.241.128.213:8002/api/v1",
  // apiurl_dev: "https://nlpbackend.xcellentlife.com/api/v1",
  // socketurl: "https://nlpsocket.xcellentlife.com/",
  // chatSocket: "https://socketchat.xcellentlife.com/"
};

export const toastConfig: jsonObj = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const reports: jsonObj = {
  "1": "Metrics & Conditions Heat Map Report",
  "2": "Metric/Condition vs Metric/Condition Trend Report",
  "3": "Metric Comparison Report",
  "4": "Individual vs Group Analysis Report",
  "5": "Health History Report",
  "6": "Survey Report",
  "7": "Group Health Report",
  "8": "Population Health Report",
  "9":"Wellness Competition Report"
};

export const reportTypeSubtitle: jsonObj = {
  "MetricsHeatMap": "This report allows you to view the concentration and number of users who have a selected health metric and level or medical condition across a particular location.",
  "MetricsConditions": "This report allows you to view the % of users across a location who have a selected health metric and level and also have the selected medical condition.",
  "MetricsComparison": "This report allows you to view a comparison between two selected metrics and the level of correlation between those two metrics across a period of time for an individual or a particular group of individuals.",
  "MetricsIndividualAndGroupAnalysis": "This report allows you to view your level of health in comparison to groups of other people where the averages of the group are compared against your recorded metrics. It provides insights into your level of health relative to a selected group.",
  "MetricsHealthHistoryReport": "This report provides you with the history of your medical conditions and medications.",
  "MetricsSurveyReport": "This report provides insights into how different groups of people are responding to the survey as well as their level of overall wellness.",
  "MetricsGroupHealthReport": "This report provides a breakdown of the overall health of the various groups or a particular group for which a person belongs.",
  "MetricsPopulationHealthReport": "This report provides a breakdown of the overall health across a particular population including the reports incidents of various chronic conditions. ",
  "MetricsWellnessCompetitionReport": "This report provides an overview of your performance in the wellness competitions you have competed in relative to other participants.",
};

export const deepLink: jsonObj = {
  "dynamicLinkInfo": {
    "domainUriPrefix": `${process.env.REACT_APP_DEEP_LINK}`,
    "androidInfo": {
      "androidPackageName": `${process.env.REACT_APP_ANDRIOD_PACKAGE_NAME}`
    },
    "iosInfo": {
      "iosBundleId": `${process.env.REACT_APP_IOS_BUNDLED_ID}`,
      "iosAppStoreId": `${process.env.REACT_APP_APP_STORE_ID}`,
    }
  }
};

export const jitsiConfig: jsonObj = {
  enableClosePage: false,
  readOnlyName: true,
  prejoinPageEnabled: false,
  enableWelcomePage: true,
  requireDisplayName: false,
  hideConferenceTimer: false,
  toolbarButtons: ["fullscreen", "closedcaptions", "filmstrip", "__end", "toggle-camera", "hangup", "microphone", "mute-everyone", "help"],
  toolbarConfig: { alwaysVisible: true },
  liveStreamingEnabled: false,
  disableInviteFunctions: true,
  useNewBandwidthAllocationStrategy: true,
  hideLobbyButton: false,
  callStatsConfigParams: {
    additionalIDs: {
      meetingsName: ""
    }
  }
};

export const interfaceJitsi = {
  APP_NAME: "Xcellent Life",
  BRAND_WATERMARK_LINK: 'https://nlp.xcellentlife.com/static/media/logo.b78b5ac9.png',
  CLOSE_PAGE_GUEST_HINT: false,
  SHOW_JITSI_WATERMARK: false,
  SHOW_BRAND_WATERMARK: true,
  SHOW_PROMOTIONAL_CLOSE_PAGE: false,
  DISABLE_PRESENCE_STATUS: false,
  MOBILE_APP_PROMO: false,
  DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
  DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://nlp.xcellentlife.com/static/media/logo.b78b5ac9.png',
};

export const paginationObject: jsonObj = {
  page_number: 0,
  total_pages: 0,
  search: ''
};